import React from 'react';
import './style.css'
import {useSelector} from "react-redux";

function Profile(props) {
    const auth = useSelector(x => x.auth.value);

    if (!auth.id) return;

    return (
        <div className="profile_page">
            <div className="container">
              <h4>Profile info</h4>

                <ul>
                    <li>Account name: {auth.username}</li>
                    <li>User name: {auth.firstName}</li>
                    <li>User surname: {auth.lastName}</li>
                    <li>User role: {auth.role || 'buyer'}</li>
                </ul>
            </div>
        </div>
    );
}

export default Profile;
