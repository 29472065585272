import {createContext, useContext, useState} from "react";

const LanguageContext = createContext(null);

const LanguageProvider = ({ children }) => {
    const [lang, setLang] = useState('en');

    return (
        <LanguageContext.Provider
            value={{
                lang,
                setLang
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
}

export const useLang = () => {
    const lang = useContext(LanguageContext);
    if (lang == null) {
        throw new Error("useLang() called outside of a LanguageProvider?");
    }
    return lang;
};


export default LanguageProvider;
