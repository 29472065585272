import './style.css'
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import RotatingText from "../../other/RotatingText";
import {useDeleteProductMutation, useGetProductQuery} from "../../../services/productsApi";
import Loader from "../../other/Loader";

const CurrentBuild = () => {
    const auth = useSelector((state) => state.auth.value);
    const {id} = useParams();
    const navigation = useNavigate();
    const { data, error, isLoading } = useGetProductQuery(id);
    const [deleteProduct, ...rest] = useDeleteProductMutation(id);

    const product = data?.data;

    const handleDelete = async(id) => {
        const resp = await deleteProduct(id)
        // await console.log('resp::: ', resp.data.success)
        !!resp?.data?.success && navigation(-1)
    }

    if (!isLoading) {
        if (!product || product.length === 0) {
            console.log('Данные пусты');
        } else {
            console.log(product);
        }
    }
    if (isLoading) {
        return <Loader/>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!product) {
        return <div>Product not found</div>;
    }


    return (
        <div>
            <div className="container">
                <div className="current_build">

                    <div className="current_build-left_side">
                        <img src={product.photo_url} alt="Photo building"/>
                    </div>
                    <div className="current_build-right-side">
                        <h2>{product.name}</h2>
                        <p className="address">Address: {product.address}</p>
                        <p className="discription">Infrastructure: In close proximity to the property, all essential
                            utilities are readily available, including electricity supply, water supply, sewage, and
                            internet access. This ensures comfortable conditions for construction and future use of the
                            property.</p>

                        <RotatingText/>

                    </div>

                    {auth ? (
                            <div className="button_config-side">
                                <button className="btn" onClick={() => handleDelete(id)}>
                                    DELETE
                                </button>
                                <NavLink to={`/catalog/edit/${product.id}`} className="btn">Edit</NavLink>
                            </div>
                    ) : null}
                </div>
                <div className="current_build__more-info">
                    <div className="item">
                       <span className="material-icons">
                            apartment
                       </span> Number of floors : <b> 10</b>
                    </div>
                    <div className="item">
                        <span className="material-icons">
                            local_parking
                        </span>Underground Parking : <b> Yes</b>
                    </div>
                    <div className="item">
                         <span className="material-icons">
                            local_hospital
                        </span>Local Hospital : <b> Yes</b>
                    </div>
                    <div className="item">
                        <span className="material-icons">
                            assignment_ind
                        </span>Building staff : <b> 15</b>
                    </div>
                    <div className="item">
                         <span className="material-icons">
                            local_parking
                        </span>Underground Parking : <b>Yes</b>
                    </div>
                    <div className="item">
                         <span className="material-icons">
                            local_parking
                        </span>Underground Parking : <b>Yes</b>
                    </div>
                    <div className="item">

                    </div>
                    <div className="item">

                    </div>
                    <div className="item">

                    </div>
                </div>
                <section className="home__section section_form">
                    <div className="title">
                        <h4>Contact US
                        </h4>
                    </div>
                    <form>
                        <div className="form-group">
                            <input type="text" className="form-control" id="name" placeholder="Name"/>
                        </div>
                        <div className="form-group">
                            <input type="email" className="form-control" id="email" placeholder="Email"/>
                        </div>
                        <div className="form-group">
                            <input type="tel" className="form-control" id="phone" placeholder="Phone"/>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" id="subject" placeholder="Subject"/>
                        </div>
                        <div className="form-group text-area-group">
                            <textarea className="form-control" id="message" rows="4" placeholder="Your Message"></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </section>
            </div>
        </div>
    );
};

export default CurrentBuild;
