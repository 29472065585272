import React, {useEffect, useState} from "react";
import './style.css';

import {Link} from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";

const Header = () => {
    const [scrolled, setScrolled] = useState(false);



    return (
        <header className={`header ${scrolled ? 'scrolled' : ''}`}>
            <div className="container">
                <Link to="/" className="logo">Emirates.Escrow</Link>
                <nav className="nav-menu">
                    <LanguageSwitcher />
                    <Link className="navLink" to="/">Home</Link>
                    <Link className="navLink" to="/catalog">Catalog</Link>
                    <Link className="navLink" to="/profile">My Account</Link>
                    <Link className="navLink" to="/">Contacts</Link>
                </nav>
            </div>

        </header>
    )
}
export default Header;
