import {Link, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';

// import { history } from '../../constants/index';
import {userActions, alertActions} from '../../store/index';

export {Register};

function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // form validation rules
    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
        username: Yup.string()
            .required('Username is required'),
        role: Yup.string(),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
    });
    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, formState} = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    async function onSubmit(data) {
        // console.log('data: ', data)
        dispatch(alertActions.clear());
        try {
            await dispatch(userActions.register(data)).unwrap();

            // redirect to login page and display success alert
            navigate('/profile/account/login');
            dispatch(alertActions.success({message: 'Registration successful', showAfterRedirect: true}));
            setTimeout(() => {
                dispatch(alertActions.clear());
            }, 3000);

        } catch (error) {
            dispatch(alertActions.error(error));
            setTimeout(() => {
                dispatch(alertActions.clear());
            }, 3000);
        }
    }

    return (
        <div className="login_page">
            <div className="container">
                <div className="login_form">
                    <h4 className="card-header">Register</h4>
                    <div className="card-body">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="mb-3">
                                <label className="form-label">First Name</label>
                                <input name="firstName" type="text" {...register('firstName')}
                                       className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.firstName?.message}</div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Last Name</label>
                                <input name="lastName" type="text" {...register('lastName')}
                                       className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.lastName?.message}</div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Username</label>
                                <input name="username" type="text" {...register('username')}
                                       className={`form-control ${errors.username ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.username?.message}</div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Password</label>
                                <input name="password" type="password" {...register('password')}
                                       className={`form-control ${errors.password ? 'is-invalid' : ''}`}/>
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>
                            <div className="mb-3 ">

                                <label className="form-label">Role</label><br/>
                                <div className="radio-side">


                                    <div className="radio-item">
                                        <input type="radio" id="Buyer" name="role" value="Buyer" {...register('role')}/>
                                        <label htmlFor="Buyer">Buyer</label><br/>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" id="Developer" name="role"
                                               value="Developer" {...register('role')} />
                                        <label htmlFor="Developer">Developer</label><br/>
                                    </div>
                                    <div className="radio-item">
                                        <input type="radio" id="broker" name="role"
                                               value="Broker" {...register('role')} />
                                        <label htmlFor="broker">Broker</label><br/>
                                    </div>
                                </div>
                            </div>
                            <div className="btn_side">
                                <button
                                    disabled={isSubmitting} className="btn btn-primary">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                    Register
                                </button>
                                <Link to="../profile/account" className="btn btn-link">Cancel</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
