import {Link, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';

import {alertActions, authActions, userActions} from '../../store';

export {Login};

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // form validation rules
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = {resolver: yupResolver(validationSchema)};

    // get functions to build form with useForm() hook
    const {register, handleSubmit, formState} = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    async function onSubmit({username, password}) {
        try {
            await dispatch(authActions.login({username, password}));

            // redirect to login page and display success alert
            navigate('/');
            dispatch(alertActions.success({message: 'Login successful', showAfterRedirect: true}));
        } catch (error) {
            dispatch(alertActions.error(error));
        }
    }

    return (
        <div className="login_page">
        <div className="container">
            <div className="login_form">
                <h4 className="card-header">Login</h4>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <label className="form-label">Username</label>
                            <input name="username" type="text" {...register('username')}
                                   className={`form-control ${errors.username ? 'is-invalid' : ''}`}/>
                            <div className="invalid-feedback">{errors.username?.message}</div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input name="password" type="password" {...register('password')}
                                   className={`form-control ${errors.password ? 'is-invalid' : ''}`}/>
                            <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                        <div className="btn_side">
                            <button disabled={isSubmitting} className="btn btn-primary">
                                {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
                                Login
                            </button>
                            <Link to="../profile/account/register" className="btn btn-link">Register</Link>
                        </div>

                    </form>
                </div>
            </div>
        </div>
        </div>
    )
}
