import LoaderImg from '../../assets/preloader.gif'
const Loader = ()=>{
    return(
        <>
            <div className="center">
                <img src={LoaderImg} alt=""/>
            </div>
        </>
    )
}
export default Loader