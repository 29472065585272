import './style.css'
import RotatingText from "../other/RotatingText";
import {useEffect} from "react";
import Btn from "../other/Btn";
import MainSlider from "../other/MainSlider";
import {useGetProductByIdQuery, useGetProductsQuery} from "../../services/productsApi";
// import Register from "../account/Register";


const Home = () => {
    // const { data, error, isLoading } = useGetProductByIdQuery('1');
    // console.log('store.state ::: ')
    useEffect(() => {
        const handleScroll = () => {
            const spContainer = document.querySelector('.sp-content');
            const offset = window.scrollY;
            spContainer.style.transform = `translate(-50%, calc(-50% + ${offset * 0.5}px))`;
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <>
            <section className="home_header">

                <div className="sp-container">

                    <div className="sp-content">
                        <div className="sp-globe"></div>
                        <h1>Emirates Escrow</h1>
                        <h2 className="frame-1">FAST</h2>
                        <h2 className="frame-2">QUALITATIVELY</h2>
                        <h2 className="frame-3">THE BEST PRICES</h2>
                        <h2 className="frame-4">TEST IT!</h2>
                        <h2 className="frame-5">
                            <span>YOUR </span>
                            <span>RELIABLE </span>
                            <span>PARTNER.</span>
                        </h2>
                        <div className="rotating_side">
                            <RotatingText/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home__section section_two">
                <div className="container">
                    <div className="ghost_text">
                        <h4 data-text="WE ARE COMPLETE SOLUTION FOR EVERY IDEA">WE ARE COMPLETE
                            SOLUTION FOR EVERY IDEA</h4>
                    </div>


                    {/*<Register />*/}


                    <p>For many years, we have been a prominent presence in the market, serving as a highly reliable
                        developer. Our long-standing track record speaks volumes about our commitment to excellence. We
                        guarantee the utmost quality in all our endeavors, ensuring that you receive the finest
                        workmanship. With an unwavering dedication to providing the best service and support, we are
                        here to stand by your side every step of the way.</p>

                </div>
                <div className="grid-side">
                    <div className="left-side_img">

                    </div>
                    <div className="right-side_services">
                        <div className="services">
                            <span className="material-icons">
                                verified_user
                            </span>
                            <h4>Complete privacy</h4>
                        </div>
                        <div className="services">
                            <span className="material-icons">
                               thumb_up
                            </span>
                            <h4>High guarantees</h4>
                        </div>
                        <div className="services">
                           <span className="material-icons">
                               manage_accounts
                           </span>
                            <h4>Support 24\7</h4>
                        </div>
                        <div className="services">
                          <span className="material-icons">
                               schedule
                          </span>
                            <h4>Best timing</h4>
                        </div>
                        <div className="services">
                            <span className="material-icons">
                               payments
                            </span>
                            <h4>Price range</h4>
                        </div>
                        <div className="services">
                            <span className="material-icons">
                                admin_panel_settings
                            </span>
                            <h4>confidentiality</h4>
                        </div>
                    </div>
                </div>
                <div className="finish_line">
                    <p>Like Our Services? Get an</p> <Btn text={"consultation"} link={"Contact"}/>
                </div>
            </section>
            <section className="home__section section_three">
                <div className="container">
                    <h4 className="title">
                        We are pleased to offer you unique collaboration pathways, unveiling before you three inspiring
                        directions.
                    </h4>
                    <div className="table">
                        <div className="section">
                            <p className="name">For Developers:</p>
                            <span>Together, we can explore the realm of novel possibilities and create innovative
                                solutions. Our expert guidance will provide you with progressive ideas, leading you to
                                new heights of success.</span>
                        </div>
                        <div className="section">
                            <p className="name">For Brokers:</p>
                            <span>Partnering with us unlocks access to innovative solutions, enabling the exploration of new horizons and pioneering approaches.
                                Our expertise will guide you towards progressive ideas, resulting in unparalleled success.</span>
                        </div>
                        <div className="section">
                            <p className="name">For Buyers:</p>
                            <span> Embrace the world of groundbreaking solutions as we embark on a journey to explore fresh possibilities.
                                Our expert guidance will introduce you to innovative ideas that pave the way for newfound success.</span>
                        </div>
                    </div>
                    <h5>These directions are just the beginning, and we are ready to tailor them to your needs, creating
                        a path that will contribute to your success and development.</h5>
                    <div className="rotatint_side">
                        <RotatingText/>
                    </div>
                </div>
                <div className="partner-slider">
                    <h3>Our Partners</h3>
                    <MainSlider/>
                </div>
            </section>
            <section className="home__section section_four">
                <div className="map-side">
                    <div className="left-side">

                    </div>
                    <div className="right-side">
                        <h4>Our Contact</h4>
                        <div className="grid-mobile">
                            <div className="left-side-grid">
                                <p className="main">Emirates Escrow - Dubai</p>
                                <p>
                                    123 Main Street, Business Tower 1, Floor 25
                                    <br/>
                                    Dubai, United Arab Emirates
                                </p>
                                <p>
                                    Phone: <a href="tel:+971456789012">+971 4 5678 9012</a>
                                    <br/>
                                    Fax: +971 4 5678 9013
                                </p>
                                <p>Email: <a href="mailto:info@emiratesescrow.ae">info@emiratesescrow.ae</a></p>
                            </div>
                            <div className="right-side-grid">
                                <p className="main">Emirates Escrow - Abu Dhabi</p>
                                <p>
                                    Sheikh Zayed Street, Office Plaza 3, Suite 450
                                    <br/>
                                    Abu Dhabi, United Arab Emirates
                                </p>
                                <p>
                                    Phone: <a href="tel:+971234567890">+971 2 3456 7890</a>
                                    <br/>
                                    Fax: +971 2 3456 7891
                                </p>
                                <p>Email: <a href="mailto:contact@emiratesescrow.ae">contact@emiratesescrow.ae</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="home__section section_form">
                <div className="title">
                    <h4>Contact US
                    </h4>
                </div>
                <form>
                    <div className="form-group">
                        <input type="text" className="form-control" id="name" placeholder="Name"/>
                    </div>
                    <div className="form-group">
                        <input type="email" className="form-control" id="email" placeholder="Email"/>
                    </div>
                    <div className="form-group">
                        <input type="tel" className="form-control" id="phone" placeholder="Phone"/>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" id="subject" placeholder="Subject"/>
                    </div>
                    <div className="form-group text-area-group">
                        <textarea className="form-control" id="message" rows="4" placeholder="Your Message"></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </section>
        </>)
}
export default Home
