import './App.css';
import Header from "./components/Header/Header";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import {Provider} from "react-redux";
// import {configDotenv} from "dotenv";
import {store} from "./store";
import LanguageProvider from "./providers/LanguageProvider";
import {AccountLayout, Login, Register} from "./components/account";
import {Alert, Nav, PrivateRoute} from "./components/other";
import {UsersLayout} from "./components/users";
import Profile from "./components/profile/Profile";
import MobileNav from "./components/mobileNav/MobileNav";
import Catalog from "./components/Catalog/Catalog";
import CurrentBuild from "./components/Catalog/currentBuild/CurrentBuild";
import AddNewProduct from "./components/NewProduct/addNewProduct";
import EditProduct from "./components/EditProduct/editProduct";


function App() {

    return (
        <>
            <Provider store={store}>
            <BrowserRouter>
                <LanguageProvider>
                <Header/>
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route path="profile/account" element={<AccountLayout />} />
                        <Route path="profile/account/login" exact element={<Login />} />
                        <Route path="profile/account/register" exact element={<Register />} />
                        <Route path="catalog" exact element={<Catalog />} />
                        <Route path="catalog/:id" element={<CurrentBuild />} />
                        <Route path="catalog/edit/:id" element={<EditProduct />} />

                        <Route element={<PrivateRoute />}>
                            <Route path="/" element={<Home />} />
                            <Route path="profile/newProduct/" element={<AddNewProduct />} />
                            <Route path="profile/users/*" element={<UsersLayout />} />
                            <Route path="profile" element={<Profile />} />
                        </Route>
                    </Routes>
                    <Nav />
                    <Alert />
                    <MobileNav/>
                <Footer/>
                </LanguageProvider>
            </BrowserRouter>
            </Provider>
        </>
    );
}

export default App;
