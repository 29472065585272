import { configureStore } from '@reduxjs/toolkit';

import { setupListeners } from '@reduxjs/toolkit/query'
import { alertReducer } from './alert.slice';
import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import catalogReducer from "./catalogReducer";
import {productApi} from "../services/productsApi";
import {api} from "../services/api";

export * from './alert.slice';
export * from './auth.slice';
export * from './users.slice';

export const store = configureStore({
    reducer: {
        alert: alertReducer,
        auth: authReducer,
        users: usersReducer,
        // catalog: catalogReducer,
        // Add the generated reducer as a specific top-level slice
        // [productApi.reducerPath]: productApi.reducer,
        [api.reducerPath]: api.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch)
