import {api} from './api'

export const productsApi = api.injectEndpoints({
    endpoints: (build) => ({
        //     login: build.mutation({
        //     query: (credentials) => ({
        //         url: 'login',
        //         method: 'POST',
        //         body: credentials,
        //     }),
        //     extraOptions: {
        //         backoff: () => {
        //             // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
        //             retry.fail({ fake: 'error' })
        //         },
        //     },
        // }),
        getProducts: build.query({
            query: () => ({url: 'products'}),
            providesTags: (result) =>
                result
                    ? [
                        ...result.data?.map(({id}) => ({type: 'Products', id})),
                        {type: 'Products', id: 'LIST'},
                    ]
                    : [{type: 'Products', id: 'LIST'}],
        }),
        addProduct: build.mutation({
            query: (body) => ({
                url: `products`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{type: 'Products', id: 'LIST'}],
        }),
        getProduct: build.query({
            query: (id) => `products/${id}`,
            providesTags: (_product, _err, id) => [{type: 'Products', id}],
        }),
        updateProduct: build.mutation({
            query(data) {
                const {id, ...body} = data
                return {
                    url: `products/${id}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: (product) => [{type: 'Products', id: product?.id}],
        }),
        deleteProduct: build.mutation({
            query(id) {
                return {
                    url: `products/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (product) => [{type: 'Products', id: product?.id}],
        }),
        getErrorProne: build.query({
            query: () => 'error-prone',
        }),
    }),
})

export const {
    useAddProductMutation,
    useDeleteProductMutation,
    useGetProductQuery,
    useGetProductsQuery,
    // useLoginMutation,
    useUpdateProductMutation,
    useGetErrorProneQuery,
} = productsApi

export const {
    endpoints: {
        // login,
        getProduct
    },
} = productsApi
