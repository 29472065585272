import {createSlice} from "@reduxjs/toolkit";


const defaultState = {
    // buildingsList: [
    //     {
    //         id: 1,
    //         project_id: 1,
    //         name: "My building 1",
    //         address: "street 1",
    //         url: "http://www.url1.com",
    //         photo_url: "https://internationalwealth.info/wp-content/uploads/2015/01/zizn_za_granicey_dubay_vishe_neba11.jpg",
    //         thumb_url: "http://www.url1.com",
    //     },
    //     {
    //         id: 2,
    //         project_id: 2,
    //         name: "My building 2",
    //         address: "street 2",
    //         url: "http://www.url2.com",
    //         photo_url: "https://internationalwealth.info/wp-content/uploads/2015/01/zizn_za_granicey_dubay_vishe_neba11.jpg",
    //         thumb_url: "http://www.url2.com",
    //     },
    //     {
    //         id: 3,
    //         project_id: 3,
    //         name: "My building 3",
    //         address: "street 2",
    //         url: "http://www.url2.com",
    //         photo_url: "https://internationalwealth.info/wp-content/uploads/2015/01/zizn_za_granicey_dubay_vishe_neba11.jpg",
    //         thumb_url: "http://www.url2.com",
    //     },
    //     {
    //         id: 4,
    //         project_id: 4,
    //         name: "My building 4",
    //         address: "street 2",
    //         url: "http://www.url2.com",
    //         photo_url: "https://internationalwealth.info/wp-content/uploads/2015/01/zizn_za_granicey_dubay_vishe_neba11.jpg",
    //         thumb_url: "http://www.url2.com",
    //     },
    //     {
    //         id: 5,
    //         project_id: 5,
    //         name: "My building 5",
    //         address: "street 2",
    //         url: "http://www.url2.com",
    //         photo_url: "https://internationalwealth.info/wp-content/uploads/2015/01/zizn_za_granicey_dubay_vishe_neba11.jpg",
    //         thumb_url: "http://www.url2.com",
    //     },
    //     {
    //         id: 6,
    //         project_id: 6,
    //         name: "My building 6",
    //         address: "street 2",
    //         url: "http://www.url2.com",
    //         photo_url: "https://internationalwealth.info/wp-content/uploads/2015/01/zizn_za_granicey_dubay_vishe_neba11.jpg",
    //         thumb_url: "http://www.url2.com",
    //     },
    // ],


};

const catalogSlice = createSlice({
    name: "catalog",
    initialState: {...defaultState},
    reducers: {
        setBuilgsList(state, action) {
            state.buildingsList = action.payload;
        }
    }
});

const {actions, reducer} = catalogSlice;

export const {
    buildingsList,
} = actions;

export default reducer;