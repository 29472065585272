export const languageOptions = [
    {
        id: "en",
        name: "English",
        flagimg:
            "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg",
    },
    {
        id: "it",
        name: "Italian",
        flagimg: "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg",
    },
]
