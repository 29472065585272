import './style.css';
import {Link} from "react-router-dom";
import {useGetProductsQuery} from "../../services/productsApi";
import {useEffect} from "react";
import Loader from "../other/Loader";


const Catalog = () =>{
    const { data, error, isLoading } = useGetProductsQuery();
    const products = data?.data;

    if (isLoading) {
        return <Loader/>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!products) {
        return <div>Product not found</div>;
    }
    return(

        <>
            <div className="catalog">
                <h2>Catalog</h2>
                <h3>Your Best Choice in the Market</h3>
                <div className="container">
                    <div className="filter">
                        <div className="filter__item">
                            <div className="checkbox">
                                <input type="checkbox" id="cb" name="cb" />
                                <label htmlFor="cb"></label>
                            </div>
                            <label htmlFor="cb"> <p>Buildings</p></label>

                        </div>
                        <div className="filter__item">
                            <div className="checkbox">
                                <input type="checkbox" id="cb1" name="cb" />
                                <label htmlFor="cb1"></label>
                            </div>
                            <label htmlFor="cb1"><p>Yachts</p></label>
                        </div>
                        <div className="filter__item">
                            <div className="checkbox">
                                <input type="checkbox" id="cb2" name="cb" />
                                <label htmlFor="cb2"></label>
                            </div>
                            <label htmlFor="cb2"> <p>Luxury goods</p></label>

                        </div>

                    </div>
                    <div className="catalog__list">
                        {!!products?.length && products.map((products) => (
                            <div key={products.id}>
                                <h4>{products.name}</h4>
                                <Link to={`/catalog/${products.id}`}>
                                    <img className="product_photo" src={products.photo_url} alt="build Photo" />
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </>
    )
}
export default Catalog;
