import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from '../../helpers/history';

export { PrivateRoute };

function PrivateRoute() {
    const auth = useSelector(x => x.auth.value);
    console.log('auth ::: ', auth)

    if (!auth) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/profile/account/login" state={{ from: history.location }} />
    }

    // authorized so return outlet for child routes
    return <Outlet />;
}
