import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Login, Register } from './';
import './style.css'

export { AccountLayout };

function AccountLayout() {
    const flag = true;
    const auth = useSelector(x => x.auth.value);
    // redirect to home if already logged in
    if (auth) {
        return <Navigate to="/" />;
    }

    return (
        <div className="account_container">
            {!flag ?
                <div>
                    Welcome Friend!
                </div> :
                <Navigate to={'profile/account/login'} />
            }
        </div>
    );
}
