import React, {useContext, useState} from 'react';
import {languageOptions} from "../../helpers/languageOptions";
import {useLang} from "../../providers/LanguageProvider"

import './style.css';

function LanguageSwitcher(props) {
    const {lang, setLang} = useLang();
    const [cssDisplay, setCssDisplay] = useState(false);

    const defaultLangFlag = <img src={languageOptions[0].flagimg} height="30" width="30" alt="nope" />;
    const [langFlag, setLangFlag] = useState(defaultLangFlag);

    const showDropdown = () => {
        if (cssDisplay === true){
            setCssDisplay(false);
        } else {
            setCssDisplay(true);
        }
    };

    const userLanguageChange = (event) => {
        setLang(event.target.id);
    }
    const handleLanguageChange = (event) => userLanguageChange(event);

    const selectListItem = (event) => {
        handleLanguageChange(event);
        setCssDisplay(false);
        setLangFlag(<img src={event.target.src} height="30" width="30" alt="nope" />)
    };

    return (
        <div className="header_lang">
            <button className="lang_switcher" onClick={showDropdown} >{langFlag}</button>
            {cssDisplay && (<ul className="lang_menu">
                {languageOptions.map((_lang) => (
                    <li id={_lang.id} key={_lang.id} disable>
                        <img onClick={selectListItem} src={_lang.flagimg} height="30" width="30" alt="flagpic" id={lang.id} />
                        {_lang.name}
                    </li>
                ))}
            </ul>
            )}
        </div>
    );
}

export default LanguageSwitcher;
