import './style.css'
import {Link} from "react-router-dom";


const Btn = (props) =>{


    return(
        <div className={props.style ? `default-btn ${props.style}`: "default-btn"}>
            {props.link ? (
                <Link className={props.textstyle ? `default-text ${props.textstyle}` : "default-text"} to={props.link}>{props.text}</Link>
            ) : (
                <p className={props.textstyle ? `default-text ${props.textstyle}` : "default-text"}>{props.text}</p>
            )}
        </div>
    )
}
export default Btn