import {NavLink} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

import {authActions} from '../../store';

export {Nav};

function Nav() {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    // only show nav when logged in
    if (!auth) return null;

    return (
        <div className="container">
            <nav className="navbar navbar-expand navbar-dark bg-dark px-3">


                <div className="navbar-nav">
                    <NavLink to="/" className="nav-item nav-link">Home</NavLink>
                    <NavLink to="profile/newProduct" className="nav-item nav-link">Add Build</NavLink>
                    <NavLink to="profile/users" className="nav-item nav-link">Users</NavLink>
                    <button onClick={logout} className="btn btn-link nav-item nav-link">Logout</button>
                </div>
            </nav>
        </div>
    );
}
