import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import React from "react";
import Slider from "react-slick";
import logo from "../../assets/logo.svg"
export default function MainSlider() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        autoplaySpeed: 4000,
        autoplay:true,

    };

    return (
        <div className="slider_container">
            <Slider className="mainSlider" {...settings}>

                <div className="slide">
                    <img src={logo} alt=""/>
                </div>
                <div className="slide">
                    <img src={logo} alt=""/>
                </div>
                <div className="slide">
                    <img src={logo} alt=""/>
                </div>
                <div className="slide">
                    <img src={logo} alt=""/>
                </div>
                <div className="slide">
                    <img src={logo} alt=""/>
                </div>
                <div className="slide">
                    <img src={logo} alt=""/>
                </div>
                <div className="slide">
                    <img src={logo} alt=""/>
                </div>

            </Slider>
        </div>
    );
}