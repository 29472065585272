import './style.css';

import {Link, useLocation} from "react-router-dom";
import {useState} from "react";

const MobileNav = () => {
    const location = useLocation();

    const isHomeActive = () => {
        return location.pathname === '/';
    };


    const isLinkActive = (linkPath) => {
        return location.pathname.startsWith(linkPath);
    };
    return (
        <div className="wrapper_mobile_nav">
            <nav className="navigation__mobile">
                <ul>
                    <li className={`${isHomeActive() ? 'active' : ''}`}>
                        <Link className="navLink" to="/">
                            <span className="icon material-icons">cottage</span>
                            <span className="text">Home</span>
                        </Link>
                    </li>
                    <li className={`${isLinkActive('/catalog') ? 'active' : ''}`}>
                        <Link className="navLink" to="/catalog">
                            <span className="icon material-icons">storefront</span>
                            <span className="text">Catalog</span>
                        </Link>

                    </li>
                    <li className={`${isLinkActive('/profile') ? 'active' : ''}`}>
                        <Link className="navLink" to="/profile">
                            <span className="icon material-icons">account_circle</span>
                            <span className="text">Profile</span>
                        </Link>
                    </li>
                    <li className={`${isLinkActive('/contacts') ? 'active' : ''}`}>
                        <Link className="navLink" to="/contacts">
                            <span className="icon material-icons">alternate_email</span>
                            <span className="text">Contacts</span>
                        </Link>
                    </li>
                    <li className={`${isLinkActive('/settings') ? 'active' : ''}`}>
                        <Link className="navLink" to="/settings">
                            <span className="icon material-icons">settings</span>
                            <span className="text">Settings</span>
                        </Link>
                    </li>
                    <div className="indicator"></div>
                </ul>
            </nav>
        </div>
    )
}
export default MobileNav;
