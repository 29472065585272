export const language = {
    eng : 'eng',
    ukr : 'ukr',
    itl: 'itl'
}

export const history = {
    navigate: null,
    location: null
};
