import React from "react";
import './style.css';

const Footer = () => {

    return (
      <footer>
          <p>Copyrights 2023 | All Rights Reserved</p>
      </footer>
    )
}
export default Footer;