import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './style.css';
import { alertActions } from '../../store';
import Success from "./Success";
import ErrorAllert from "./ErrorAllert";

export { Alert };

function Alert() {
    const dispatch = useDispatch();
    const location = useLocation();
    const alert = useSelector(x => x.alert.value);

    useEffect(() => {
        // clear alert on location change
        dispatch(alertActions.clear());
    }, [location]);

    if (!alert) return null;

    return (
        <div className="container">
            {/*alert-danger*/}
            {/*alert-success*/}

                <div className={`alert alert-dismissible ${alert.type}`}>
                    {alert.type === 'alert-success' ? (
                        <Success />
                    ) : alert.type === 'alert-danger' ? (
                        // <Danger />
                        <ErrorAllert/>
                    ) : null}

                    <p className="alert-message">
                        {alert.message}
                    </p>
                    <button type="button" className="btn-close" onClick={() => dispatch(alertActions.clear())}>
                    <span className="material-icons">
                               close
                            </span>
                    </button>
                </div>

        </div>
    );
}
