import "./style.css";
import {useAddProductMutation, useGetProductQuery} from "../../services/productsApi";
import {useState} from "react";
import {alertActions} from "../../store";
import {useDispatch} from "react-redux";

const AddNewProduct =()=>{
    const dispatch = useDispatch();
    const [addProduct, { isLoading }] = useAddProductMutation();
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        photo_url: '',
        project_id: 1,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        addProduct(formData);
        dispatch(alertActions.success({message: 'Building Create'}));
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 2000);
        setFormData({
            name: '',
            address: '',
            photo_url: '',
            project_id: 1,
        });
    };

    return(
        <>
            <div className="container addNewProduct_page">
                <div className="title">
                    <h4>Add Your Buildings</h4>
                </div>
                <form className="addNewProduct_form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Name Your Object"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            placeholder="Address Your Object"
                            value={formData.address}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            id="photo_url"
                            name="photo_url"
                            placeholder="Url For Your Photo"
                            value={formData.photo_url}
                            onChange={handleChange}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </form>
            </div>

        </>
    )
}
export default AddNewProduct
